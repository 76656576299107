import { useState, useEffect } from "react";
import myImage from "../images/arrow.gif";
import moment from "moment";
function DayWise({ dayGameData }) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentTime = moment().format("HH:mm");

  const [data, setGameData] = useState([]);

  useEffect(() => {
    setGameData(dayGameData);
  }, [dayGameData]);

  // based on current date and time get data
  const getTodayResult = (gameData) => {
    const itemTime = moment(gameData.open_time, "HH:mm");
    const currentMoment = moment(currentTime, "HH:mm");

    if (gameData?.curr_date?.date === currentDate) {
      return currentMoment.isSameOrAfter(itemTime)
        ? gameData?.curr_date?.result || ""
        : "";
    }
    return "";
  };

  return (
    <div className="col-md-12 pt-4 text-center">
      <div className="row">
        <div className="col-12">
          <a href="/">
            {" "}
            <button
              type="refresh"
              className="text-center refreshButton col-md-7 col-sm-11 mb-5"
            >
              Result / Refresh
            </button>
          </a>
        </div>
        {data && data.length > 0 ? (
          data?.map((gameData, index) => (
            // <div className="col-md-6 col-sm-12 gameBox text-center">
            <div
              key={index}
              className={`col-md-${
                index === 14 ? 12 : 6
              } col-sm-12 game_column text-center`}
            >
              <div className="games bg-sucess">
                <h6 className="pt-2">{gameData?.game_name}</h6>
                <span className="pt-2">( {gameData?.open_time} )</span>
                <div className="resultGame">
                  <span className="pt-2">
                     {` ${gameData?.prev_date?.result || " "}`}{" "}
                  </span>
                  <span>
                    <img src={myImage}></img>
                  </span>
                  <span className="pt-2">
                    [<span>{getTodayResult(gameData)}</span>]
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>data not available</p>
        )}
      </div>
    </div>

    // <div className="col-12">

    //     <div className="row">
    //         {gameData && gameData.length > 0 ? (
    //             gameData.map((game, index) => (
    //                 <div key={index} className={`col-md-${(index === 0 || index === 7 || index === 14) ? 12 : 6} col-sm-12 game_column`}>
    //                     <div className='d-flex align-items-center flex-column'>
    //                         {/* <p className="mb-0 pt-2 fw-bold fs-6 text-light">{game?.game_name}</p> */}
    //                         <h6 className="mb-0 pt-2 fw-bold fs-6">{game?.game_name}</h6>
    //                         {/* <p className="mb-0 text-warning fs-6">{game?.open_time}</p> */}
    //                         <p className="mb-0 fs-6 textColor">( {game?.open_time} )</p>
    //                         <div className="d-flex align-items-end text-center">
    //                             <div>
    //                                 {/* <p className="mb-0 fs-6 text-light">Last</p> */}
    //                                 {/* <sapn class="btn"></sapn> */}
    //                                 <span class="btn">{game?.prev_date?.result || ' '}</span>
    //                             </div>
    //                             <div>
    //                                 {/* <img src={myImage}></img> */}
    //                             </div>
    //                             <div>
    //                                 {/* <p className="mb-0 fs-6 text-light">Today</p> */}
    //                                 {/* <sapn class="btn"></sapn> */}
    //                                 <span class="btn">[ {game?.curr_date?.result || ' '} ]</span>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             ))
    //         ) : (
    //             <p></p>
    //         )}
    //     </div>

    // </div>
  );
}

export default DayWise;
