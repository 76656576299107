import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import YearTable from './yearTable';

const monthFullName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

function YearlyFooterDrop() {
    const [selectedOption, setSelectedOption] = useState(null);
    const [selecYear, setSelectedYear] = useState(null);
    const [sendList, setSendList] = useState([]);
    const isYearTable = window.location.search.includes('?');
    useEffect(() => {
        fetch("https://api.sattakingvip.co.in/getGameName", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        })
          .then((response) => response.json())
          .then((json) => setSendList(json))
          .catch((error) => console.error(error));

        // Parse URL parameters and set dropdown values
        const params = new URLSearchParams(window.location.search);
        const value = params.get('value');
        if (value) {
            const [gameName, year] = value.split('-');
            setSelectedOption({ value: gameName, label: gameName });
            setSelectedYear({ value: parseInt(year), label: year });
        }
    }, []);

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };

    const handleChangeYear = (selecYear) => {
        setSelectedYear(selecYear);
    };

    const customStyles = {

        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#c6007e',  // Background color of the select box
            color: '#fff',               // Font color
            borderColor: state.isFocused ? '#000' : '#ccc',
            minHeight: '46px',
            height: '46px',
            boxShadow: state.isFocused ? null : null,
            '&:hover': {
                borderColor: state.isFocused ? '#000' : '#ccc',
            }
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#b9007c' : '#fff', // Background color of the dropdown options
            color: state.isSelected ? '#fff' : '#333',           // Font color of the dropdown options
            '&:hover': {
                backgroundColor: state.isSelected ? '#b9007cad' : '#b9007cad', // Hover state background color
                color: state.isSelected ? '#fff' : '#fff',           // Hover state font color
            }
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#fff',  // Font color of the selected option
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#fff',  // Background color of the dropdown menu
            zIndex: 100,
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '46px',
            padding: '0 8px',
        }),
        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '46px',
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: '#fff',  // Placeholder font color
        }),



    };

    const getYearGameDetails = () => {
        debugger
        if (selectedOption && selecYear) {
            const encodedGameName = encodeURIComponent(selectedOption.value);
            const value = `${encodedGameName}-${selecYear.value}`;
            const newUrl = `${window.location.pathname}?value=${value}`;
            window.history.pushState(null, '', newUrl);
            window.location.reload();
            // setSelectedOption(selectedOption.value)
            // setSelectedYear(selecYear.value)


        } else {
            toast("All Fields Required");
        }
    };
    const currentYear = new Date().getFullYear();
    let yearOptions = [];
    for (let year = currentYear - 9; year <= currentYear; year++) {
        yearOptions.push({ value: year, label: year });
    }

    return (
        <div>

            <div className="footer" id='yearTableid'>
                {
                    (isYearTable) && (
                        <>
                            <YearTable />

                            <div className="yellow-container">
                                <div className="bottom-container">
                                    <div className="selection-container">
                                        <div className="col-12">
                                            <div className="row">

                                                <div className="col-l-4 col-md-4 col-sm-12 col-xs-12 pb-2">
                                                    <Select
                                                        menuPlacement="auto"
                                                        value={selectedOption}
                                                        onChange={handleChange}
                                                        options={sendList}
                                                        placeholder="Select Game"
                                                        styles={customStyles}
                                                    />
                                                </div>
                                                <div className="col-l-4 col-md-4 col-sm-12 col-xs-12 pb-2">
                                                    <Select
                                                        menuPlacement="auto"
                                                        value={selecYear}
                                                        onChange={handleChangeYear}
                                                        options={yearOptions}
                                                        placeholder="Select Year"
                                                        styles={customStyles}
                                                    />
                                                </div>
                                                <div className="col-l-4 col-md-4 col-sm-12 col-xs-12 pb-2">
                                                    <button className="form-control" id="go-button" onClick={getYearGameDetails}>Go</button>
                                                    <ToastContainer
                                                        autoClose={2000}
                                                        position="top-right"
                                                        hideProgressBar={false}
                                                        newestOnTop={true}
                                                        closeOnClick
                                                        pauseOnHover
                                                        draggable={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    );
}

export default YearlyFooterDrop;
