import { Table } from "antd";
// import 'antd/dist/antd.css'
import { useState, useEffect } from "react";
import moment from "moment";
const momenttz = require("moment-timezone");

function MonthTable({ gameData, dropValue }) {
  // const gameLength = (gameData != undefined)
  const currentMonth = moment().tz("Asia/Kolkata").month();
  const currentYear = moment().tz("Asia/Kolkata").year();
  const numberDaysMonth = moment().tz("Asia/Kolkata").daysInMonth();
  const [tableColumn, setTableColumn] = useState([]);
  const [monthData, setMonthData] = useState([]);
  const [gameResult, setGameResult] = useState([]);
  var selectMonthDrop;
  useEffect(() => {
    if (dropValue) {
      setGameResult(dropValue);
      // console.log('gameResult', gameResult)
      if (gameResult == "") {
        selectMonthDrop = moment().tz("Asia/Kolkata").format("MMMM");
        // console.log('if======>', selectMonthDrop)
      } else {
        selectMonthDrop = moment(
          `${dropValue?.year}-${dropValue?.month}-01`
        ).format("MMMM");
        // console.log('else======>', selectMonthDrop)
      }
      // console.log(gameResult);
      // console.log()
    } else {
    }
  });

  useEffect(() => {
    if (gameData) {
      setMonthData(gameData);
    }
  }, [gameData]);

  useEffect(() => {
    if (monthData.length) {
      let dayArray = Object.keys(monthData[0]);
      for (let i = 0; i < dayArray.length; i++) {
        dayArray[i] = {
          title: dayArray[i] === "day" ? selectMonthDrop : dayArray[i],
          dataIndex: dayArray[i],
          key: dayArray[i],
        };
      }
      setTableColumn(dayArray);
    }
  }, [monthData]);

  useEffect(() => {
    fetch("https://api.sattakingvip.co.in/getmonthdata", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        month: currentMonth + 1,
        year: currentYear,
        gameName: "",
        result: "",
        days: numberDaysMonth,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        setMonthData(json);
      })
      .catch((error) => console.error(error));
  }, []);
  return (
    <>
      <div className="monthYrTbl" id="monthTable">
        {!(dropValue == null) && (
          <h5
            className="text-center bg-dark text-white p-2"
            style={{ display: "block" }}
          >
            {/* {dropValue?.selectedOption?.value} MONTHLY RECORD CHART {dropValue?.selecYear?.value} */}
            {dropValue?.gameName} MONTHLY RECORD CHART {selectMonthDrop} -{" "}
            {dropValue?.year || currentYear}
          </h5>
        )}
        <div className="table-responsive">
          <Table
            dataSource={monthData}
            columns={tableColumn}
            pagination={false}
          />
        </div>
      </div>
      <div className=""></div>
    </>
  );
}
export default MonthTable;
