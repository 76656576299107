import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './media.css'
// import 'antd/dist/antd.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import BannerContent from './js/bannerContent';
import MonthTable from './monthTable';
import FooterDropBtn from './js/footerDropBtn';
// import AllGameButtons from './js/allGameButtons';
import YearTable from './js/yearTable';
import YearlyFooterDrop from './js/yearlyFooterDrop';
import CookiePolicy from './js/cookiesPolicy';
import PrivacyPolicy from './js/privacyPolicy';
import Footer from './js/footer';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div className='custom-container'>
    <Router>
      <App />
      <FooterDropBtn />
      {/* <YearlyFooterDrop /> */}
      <Footer />
      <BannerContent />
    </Router>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
