import CookiePolicy from "./cookiesPolicy"
import PrivacyPolicy from "./privacyPolicy"
import { Routes, Route } from "react-router-dom"

function FooterContent() {
    return (
        <div>
            
            <Routes>
                <Route path="/cookie-policy" element={<CookiePolicy />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            </Routes>
        </div>
    )
}
export default FooterContent